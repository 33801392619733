














































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ProjectEntityModel, ProjectQueryModel } from '@common-src/entity-model/project-entity';
import ProjectService from '@common-src/service/project';
import { UserStoreModule } from '@common-src/store/modules/user';
import CommonService from '@common-src/service/common';

@Component
export default class ProjectListComponent extends TableDialogFormComponent<ProjectEntityModel, ProjectQueryModel> {
    ProjectEntityModel = ProjectEntityModel;
    created() {
        this.initTable({
            service: ProjectService,
            queryModel: new ProjectQueryModel(),
            tableColumns: ProjectEntityModel.getTableColumns()
        });
        this.getList();
    }

    async selectProjectClick(project: ProjectEntityModel) {
        UserStoreModule.SET_CURRENT_PROJECT(project);
        await CommonService.getUserPermissions2(0);
        this.$router.push(UserStoreModule.RedirectPath);
    }

    projectFormDialogOK() {
        this.formDialogOK();
        this.$eventHub.$emit('DASHBOARD_INIT_PROJECT');
    }
    // 应用初始化
    initClick(model: ProjectEntityModel) {
        this.startFullScreenLoading('初始化...');
        (this.service as any).initProject(model.id)
            .then(() => {
                this.showMessageSuccess('初始化成功！');
                this.getList();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }
}

